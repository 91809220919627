import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class JuzgadoCategoriaService {
  getAll () {
    return http.get(`${baseUrl}/juzgado-categorias/all`)
  }

  search (params) {
    return http.get(`${baseUrl}/juzgado-categorias/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
