<template>
    <div class="flex justify-center items-center mt-10">
        <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
            <div class="text-center my-4">
                <h2 class="text-lg font-bold">
                    Crear Juzgado
                </h2>
            </div>
            <div class="flex flex-wrap content-around">
                <div class="w-full flex flex-col items-center py-5 px-2 ">
                    <div class="px-2 w-full flex flex-col">
                    <div class="mb-4" style="width: 100% !important;">
                        <!-- <dropdown
                        placeholder="Tipo de juzgado"
                        :options="juzgadoCategorias"
                        option-label="nombre"
                        option-value="id"
                        v-model="model.categoriaId"
                        class="w-full"
                        /> -->
                        <AutoComplete
                          class="w-full"
                          inputClass="w-full"
                          v-model="selectedJuzgadoCategoria"
                          :suggestions="suggestionsCategoria"
                          @complete="searchJuzgadoCategoria($event)"
                          placeholder="Tipo de juzgado"
                          field="nombre"
                        />
                        <MessageError :text="errors.categoriaId"/>
                    </div>
                    <div class="mb-4">
                        <dropdown
                        placeholder="Seleccione numero de juzgado"
                        class="w-full"
                        :options="noJuzgados"
                        option-label="nombre"
                        option-value="nombre"
                        v-model="model.code"
                        :filter="true"
                        />
                        <MessageError :text="errors.code"/>
                    </div>
                    </div>
                    <div class="px-2 w-full flex flex-col">
                    <div class="mb-4">
                        <AutoComplete
                        class="w-full"
                        inputClass="w-full"
                        v-model="selectedJuzgado"
                        :suggestions="suggestions"
                        @complete="searchJuzgado($event)"
                        @item-select="selectJuzgado($event)"
                        field="nombre"
                        placeholder="Nombre del juzgado"
                        />
                        <MessageError :text="errors.nombre"/>
                    </div>
                    </div>
                    <div class="px-2 w-full flex flex-col">
                    <div class="mb-4">
                        <dropdown
                        placeholder="Seleccione departamento"
                        class="w-full"
                        :options="departamentos"
                        option-label="nomDpto"
                        option-value="codDpto"
                        v-model="model.departamentoId"
                        @change="getMpio"
                        :filter="true"
                        />
                        <MessageError :text="errors.departamentoId"/>
                    </div>
                    <div class="mb-4">
                        <dropdown
                        class="w-full"
                        placeholder="Seleccione municipio"
                        :options="municipios"
                        option-label="nomMpio"
                        option-value="codMpio"
                        v-model="model.municipioId"
                        :filter="true"
                        />
                        <MessageError :text="errors.municipioId"/>
                    </div>
                    </div>
                </div>
                <div class="w-full flex justify-between px-2 mb-9">
                    <Button  label="Cancelar"
                            class="bg-blue-900 p-button-outlined"
                            style="width: 244px;"
                            @click="$router.go(-1)"
                    />
                    <Button  label="Crear"
                            class="bg-blue-900"
                            style="width: 244px;"
                            type="button"
                            @click="handleNext"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import juzgadoCategoriaStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/juzgadoCategoria.store'
import storeLocalizacion from '@/apps/pharmasan/juridica/procesosJuridicos/store/localizacion.store'
import JuzgadoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/juzgado.service'
import JuzgadoCategoriaService from '../../../../services/juzgadoCategoria.service'
import NoJuzgadosService from '../../../../services/noJuzgados.service'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import juzgadoStore from '../../../../store/juzgado.store'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { useRouter } from 'vue-router'
export default {
  name: 'crearJuzgado',
  setup () {
    const toast = useToast()
    const router = useRouter()
    const _JuzgadoService = new JuzgadoService()
    const _JuzgadoCategoriaService = new JuzgadoCategoriaService()
    const _NoJuzgadosService = new NoJuzgadosService()
    /** state **/
    const schema = yup.object({
      categoriaId: yup.number().label('').nullable(),
      nombre: yup.string().max(255).nullable().required('El campo es requerido').label(''),
      departamentoId: yup.string().required('El campo es requerido').label(''),
      municipioId: yup.string().required('El campo es requerido').label(''),
      code: yup.string().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('categoriaId')
    useField('nombre')
    useField('departamentoId')
    useField('municipioId')
    useField('id')
    useField('code')
    /** data **/
    const suggestions = ref([])
    const selectedJuzgadoCategoria = ref(null)
    const categoria = ref({ nombre: null })
    const selectedJuzgado = ref(null)
    const suggestionsCategoria = ref([])
    /** computed **/
    const juzgadoCategorias = computed(() => juzgadoCategoriaStore.getters._juzgadoCategorias)
    const departamentos = computed(() => storeLocalizacion.getters._departamentos)
    const noJuzgados = ref([])
    const municipios = computed(() => storeLocalizacion.getters._municipios)
    /** methodos **/
    const handleNext = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      values.categoria = categoria.value
      delete values.id
      juzgadoStore.dispatch('createJuzgado', values).then(({ data }) => {
        if (data.error) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El documento ya existe', life: 3000 })
        } else {
          Swal.fire({
            title: 'Exito',
            text: 'Juzgado registrado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
        }
      })
    })
    const getMpio = ({ value }, municipioId = null) => {
      if (!value) {
        storeLocalizacion.commit('setMunicipios', [])
      } else {
        storeLocalizacion.dispatch('getMunicipios', value).then(() => {
          model.municipioId = municipioId
        })
      }
    }
    const searchJuzgadoCategoria = ({ query }) => {
      _JuzgadoCategoriaService.search({ nombre: query }).then(({ data }) => {
        suggestionsCategoria.value = data
      })
    }
    const searchJuzgado = ({ query }) => {
      _JuzgadoService.search({ nombre: query }).then(({ data }) => {
        suggestions.value = data
      })
    }
    const selectJuzgado = ({ value }) => {
      if (!value) return
      model.id = value.id
      model.categoriaId = value.categoriaId
      model.nombre = value.nombre
      model.departamentoId = value.departamentoId
      model.code = value.code
      getMpio({ value: value.departamentoId }, value.municipioId)
    }
    watch(selectedJuzgado, (val) => {
      if (typeof val === 'string') {
        model.nombre = val
      }
    })
    watch(selectedJuzgadoCategoria, (val) => {
      if (val.id) {
        model.categoriaId = val.id
      } else {
        categoria.value.nombre = val
        model.categoriaId = null
      }
    })

    /** hooks **/
    onMounted(() => {
      juzgadoCategoriaStore.dispatch('getJuzgadoCategorias')
      storeLocalizacion.dispatch('getDeparatamentos')
      _NoJuzgadosService.paginate().then(({ data }) => {
        noJuzgados.value = data.rows
      })
    })
    return {
      juzgadoCategorias,
      errors,
      model,
      handleNext,
      getMpio,
      departamentos,
      noJuzgados,
      municipios,
      searchJuzgado,
      suggestions,
      selectJuzgado,
      selectedJuzgado,
      selectedJuzgadoCategoria,
      searchJuzgadoCategoria,
      suggestionsCategoria,
      categoria
    }
  }
}
</script>

<style scoped>

</style>
