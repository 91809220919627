import { createStore } from 'vuex'
import JuzgadoService from '../services/juzgado.service'
const _JuzgadoService = new JuzgadoService()
const storeJuzgados = {
  state: {
    _juzgados: [],
    _juzgado: {
      rows: [],
      count: 0
    }
  },
  getters: {
    juzgados: state => state._juzgados,
    juzgado: state => state._juzgado
  },
  mutations: {
    setJuzgados (state, payload) {
      state._juzgados = payload
    },
    setJuzgado (state, payload) {
      state._juzgado = payload
    },
    setUpdateJuzgado (state, payload) {
      state._juzgado.nombre = payload.nombre
      state._juzgado.categoriaId = payload.categoriaId
      state._juzgado.code = payload.code
      state._juzgado.departamentoId = payload.departamentoId
      state._juzgado.municipioId = payload.municipioId
    },
    setDefault (state) {
      state._juzgado = {}
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      return _JuzgadoService.paginate(payload).then(({ data }) => {
        commit('setJuzgados', data)
        return data
      })
    },
    createJuzgado ({ commit }, payload) {
        return _JuzgadoService.save(payload).then((response) => {
            return response
        })
    },
    findJuzgado ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _JuzgadoService.find(id).then(({ data }) => {
          commit('setJuzgado', data)
          resolve(data)
        })
      })
    },
    updateJuzgado ({ getters }) {
      return new Promise((resolve, reject) => {
        const id = getters.juzgado.id
        _JuzgadoService.edit(getters.juzgado, id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    removeJuzgado ({ commit }, id) {
      return _JuzgadoService.remove(id).then((response) => {
        return response
      })
    }
  }
}
const store = createStore(storeJuzgados)
export default {
  namespaced: true,
  ...store
}
