import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class JuzgadoService {
  search (params) {
    return http.get(`${baseUrl}/juzgado/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/juzgado/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  save (payload) {
    return http.post(`${baseUrl}/juzgado`, payload, {
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/juzgado/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  edit (payload, id) {
    return http.put(`${baseUrl}/juzgado/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  remove (id) {
    return http.delete(`${baseUrl}/juzgado/${id}`, {
      headers: {
        loading: true
      }
     })
  }
}
