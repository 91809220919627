import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS

export default class IncidenciasService {
  paginate (params) {
    return http.get(`${baseUrl}/no-juzgados/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
