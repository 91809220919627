import JuzgadoCategoriaService from '../services/juzgadoCategoria.service'
import { createStore } from 'vuex'
const _JuzgadoCategoriaService = new JuzgadoCategoriaService()
const juzgadoCategoriaStore = {
  state: {
    juzgadoCategorias: []
  },
  getters: {
    _juzgadoCategorias: state => state.juzgadoCategorias
  },
  mutations: {
    setJuzgadoCategorias (state, payload) {
      state.juzgadoCategorias = payload
    }
  },
  actions: {
    getJuzgadoCategorias ({ commit }) {
      _JuzgadoCategoriaService.getAll().then(({ data }) => {
        commit('setJuzgadoCategorias', data)
      })
    }
  }
}
const store = createStore(juzgadoCategoriaStore)
export default {
  namespaced: true,
  ...store
}
